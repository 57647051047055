






























import Vue from 'vue'
import getStaticPage from '~/apollo/queries/getStaticPage.gql'

export default Vue.extend({
  async fetch () {
    const getStaticPageQuery = await this.$apolloProvider.defaultClient.query({
      query: getStaticPage,
      variables: {
        slug: this.$route.params.slug
      }
    })
    this.page = getStaticPageQuery.data.staticPage

    if (!this.page) {
      this.$router.push({ path: '/' })
    }
  },
  data () {
    return {
      page: null as any
    }
  },
  head (): any {
    return {
      title: this.page ? this.page.metaTitle : '',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.page ? this.page.metaDescription : ''
        }
      ]
    }
  }
})
