var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page)?_c('div',[_c('div',{staticClass:"page-title-container"},[_c('div',{staticClass:"row m0"},[_c('div',{staticClass:"col-12 pt-0 pb-0 pr-0 pl-0"},[_c('h1',{staticClass:"page-title pt-0 pb-0 mb-2"},[_vm._v("\n          "+_vm._s(_vm.page.title)+"\n        ")])])])]),_vm._v(" "),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"col-12 p0"},[(_vm.page.content)?_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.page.content.html.replace(/\u2028/g, '')
          .replace(/(<p><\/p>)/g, '<p aria-hidden=true></p>')
          .replace(/(<h1><\/h1>)/g, '<h1 aria-hidden=true></h1>')
          .replace(/(<h2><\/h2>)/g, '<h2 aria-hidden=true></h2>')
          .replace(/(<h3><\/h3>)/g, '<h3 aria-hidden=true></h3>')
          .replace(/(<h4><\/h4>)/g, '<h4 aria-hidden=true></h4>')
          .replace(/(<h5><\/h5>)/g, '<h5 aria-hidden=true></h5>')
          .replace(/(<h6><\/h6>)/g, '<h6 aria-hidden=true></h6>')),expression:"page.content.html.replace(/[\\u{2028}]/gu, '')\n          .replace(/(<p><\\/p>)/g, '<p aria-hidden=true></p>')\n          .replace(/(<h1><\\/h1>)/g, '<h1 aria-hidden=true></h1>')\n          .replace(/(<h2><\\/h2>)/g, '<h2 aria-hidden=true></h2>')\n          .replace(/(<h3><\\/h3>)/g, '<h3 aria-hidden=true></h3>')\n          .replace(/(<h4><\\/h4>)/g, '<h4 aria-hidden=true></h4>')\n          .replace(/(<h5><\\/h5>)/g, '<h5 aria-hidden=true></h5>')\n          .replace(/(<h6><\\/h6>)/g, '<h6 aria-hidden=true></h6>')"}],staticClass:"static-content"}):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }